import React, { useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import throttle from "lodash-es/debounce"
import { useStateContext } from "@context/stateContext"

const isBrowser = () => typeof window !== "undefined"

function StickyContactButton() {
  const [showStickyContactButton, setShowStickyContactButton] = useState(false)
  const { openContactModal } = useStateContext()
  const onWindowScroll = throttle(handleStickyContactButtonVisibility, 50, {
    trailing: true,
    leading: true,
  })
  useEffect(() => {
    isBrowser() && window.addEventListener("scroll", onWindowScroll)
    return () => {
      isBrowser() && window.removeEventListener("scroll", onWindowScroll)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleStickyContactButtonVisibility() {
    if (window.scrollY > 500) setShowStickyContactButton(true)
    if (window.scrollY < 500) setShowStickyContactButton(false)
  }
  return (
    <AnimatePresence>
      {showStickyContactButton && (
        <motion.div
          onClick={() => openContactModal()}
          className={`sticky-contact font-bold fixed z-40 cursor-pointer w-24 h-24 md:w-28 md:h-28 rounded-full shadow-xl bottom-4 right-4 md:bottom-10 md:right-10 flex justify-center items-center bg-yellow`}
          initial={"initial"}
          animate={"present"}
          exit={"exit"}
          variants={buttonVariants}
        >
          <motion.svg
            className="absolute"
            width="100%"
            height="100%"
            viewBox="0 0 112 113"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            animate={{ scale: 1.04 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          >
            <ellipse
              scale="0.663722"
              cx="56"
              cy="56.4858"
              rx="56"
              ry="56.4858"
              fill="#99AA1F"
            />
            <circle cx="56" cy="56.4854" r="51" fill="#E6FF00" />
            <circle
              cx="56"
              cy="56.9717"
              r="48"
              stroke="#2E3449"
              strokeWidth="2"
            />
          </motion.svg>
          <span className={`relative text-14 md:text-base font-ubuntu`}>
            Kontakt
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default StickyContactButton

const buttonVariants = {
  initial: { scale: 0 },
  exit: { scale: 0 },
  present: {
    scale: 1,
  },
}
