import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { motion } from "framer-motion"
import parse from "html-react-parser"

const Button = ({
  buttonStyle,
  buttonClasses,
  buttonText,
  buttonHref,
  onClick,
}) => {
  return (
    <motion.div
      className="flex w-full hp-button pt-c40 md:pt-c32 2xl:pt-c40 xs:w-auto"
      onClick={onClick}
    >
      <motion.span
        className="w-full cursor-pointer xs:w-auto"
        style={{ boxShadow: "4px 4px 0px #000" }}
        initial={{ y: 0 }}
        whileHover={{ y: -1, boxShadow: "5px 5px 1px #000" }}
        whileTap={{ y: 1, boxShadow: "1px 1px 1px #000" }}
      >
        {!buttonHref?.includes("mailto:") && (
          <Link
            to={buttonHref ?? "/"}
            style={buttonStyle}
            className={`${
              onClick === undefined ? "" : "pointer-events-none"
            } ${buttonClasses}`}
          >
            {parse(buttonText)}
          </Link>
        )}
        {buttonHref?.includes("mailto:") && (
          <a
            href={buttonHref ?? "/"}
            style={buttonStyle}
            className={buttonClasses}
          >
            {parse(buttonText)}
          </a>
        )}
      </motion.span>
    </motion.div>
  )
}

Button.propTypes = {
  buttonData: PropTypes.object,
}

export default Button
